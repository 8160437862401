<template>
  <li>
    <div class="tf-nc">
      <slot name="item" :item="item" />
    </div>
    <ul v-if="children.length">
      <tf-node v-for="child in children" :item="child" :key="uid(child.id)">
        <template #item="{ item: childItem }">
          <slot name="item" :item="childItem" />
        </template>
      </tf-node>
    </ul>
  </li>
</template>

<script lang="ts">
import { get, uniqueId } from "lodash";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: { TfNode },
})
export default class TfNode extends Vue {
  @Prop(Object) readonly item!: Record<string, any>;

  get children(): Record<string, any>[] {
    return get(this.item, "children", []);
  }

  uid(prefix?: string) {
    return uniqueId(prefix);
  }
}
</script>
